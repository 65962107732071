.modalDialog {
  align-items: flex-start !important;
}

.modalBody {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.modalHeader {
  > button {
    background-color: unset;
  }
}
